export const Constants = {
  //login
  LOGIN_URI: process.env.REACT_APP_BASEURL + "/api/account/login",
  //logout
  USER_SIGNOUT_URI: process.env.REACT_APP_BASEURL + "/api/account/logout",
  SIGNUP_URI: process.env.REACT_APP_BASEURL + "/api/account/signup",
  GET_CREATED_USER_URI: process.env.REACT_APP_BASEURL + "/api/account/create",
  POST_REQUEST_ADMIN_FOR_ADMIN_ACCESS_URI:
    process.env.REACT_APP_BASEURL + "/api/account/requestadminaddition",
  POST_REQUEST_ADMIN_FOR_USER_ACCESS_URI:process.env.REACT_APP_BASEURL + "/api/account/requestuseraddition",
  POST_REQUEST_ADMIN_TO_ACTIVATE_ACCOUNT:process.env.REACT_APP_BASEURL + "/api/account/requestuseractivate",

  // Create User
  CREATE_USER_URI: process.env.REACT_APP_BASEURL + "/api/account/createuser",

  //Create
  GET_DETAILS:process.env.REACT_APP_BASEURL + "/api/account/create/details",

  //Common
  COUNTRY_GET_URI: process.env.REACT_APP_BASEURL + "/api/dashboard/countries",
  TIMEZONE_GET_URI: process.env.REACT_APP_BASEURL + "/api/dashboard/timezones",
  STATEBYCOUNTRYID_GET_URI:
    process.env.REACT_APP_BASEURL + "/api/dashboard/states",


  //Dashboard
  USER_PROFILE_PICTURE:
    process.env.REACT_APP_BASEURL + "/api/dashboard/profilepicture",
  ORGANIZATION_GET_DETAILS_URI:
    process.env.REACT_APP_BASEURL + "/api/dashboard/details",
  ORGANIZATION_GET_EDIT_URI:
    process.env.REACT_APP_BASEURL + "/api/dashboard/edit",
  ORGANIZATION_PUT_EDIT_URI:
    process.env.REACT_APP_BASEURL + "/api/dashboard/edit",
  CHECK_ORGANIZATION_EMAIL_EXIST:process.env.REACT_APP_BASEURL + "/api/saas/checkorgnaizationemail",

  //User
  USER_ME_URI: process.env.REACT_APP_BASEURL + "/api/users/me",
  USER_PAGE_GET_DETAILS_URI:
    process.env.REACT_APP_BASEURL + "/api/users/details",
  USER_GET_ALL_URI: process.env.REACT_APP_BASEURL + "/api/users",
  USER_POST_URI: process.env.REACT_APP_BASEURL + "/api/users/create",
  USER_EDIT_URI: process.env.REACT_APP_BASEURL + "/api/users/edit",
  USER_DELETE_URI: process.env.REACT_APP_BASEURL + "/api/users/delete",
  USER_CHECK_EMAIL_URI: process.env.REACT_APP_BASEURL + "/api/users/checkemail",
  USER_ACTIVE_TOGGLE_URI:
    process.env.REACT_APP_BASEURL + "/api/users/activetoggle",
  USER_ADMIN_TOGGLE_URI:
    process.env.REACT_APP_BASEURL + "/api/users/admintoggle",
  IMPORT_USER_FROM_CSV_URI:
    process.env.REACT_APP_BASEURL + "/api/users/importuser",
    SEARCH_USER_BY_EMAIL: process.env.REACT_APP_BASEURL + "/api/users/search",
    SEARCH_ALL_USER:process.env.REACT_APP_BASEURL + "/api/users/searchall",

  //School    
  TEACHER_GET_ALL_URI: process.env.REACT_APP_BASEURL + "/api/school/teachers",
  STUDENT_GET_ALL_URI: process.env.REACT_APP_BASEURL + "/api/school/students",
  MODIFY_TEAMS_COLLECTION:
    process.env.REACT_APP_BASEURL + "/api/school/modifyteamcollection",

  //Group
  GROUP_PAGE_GET_DETAILS_URI:
    process.env.REACT_APP_BASEURL + "/api/group/details",
  GROUP_GET_ALL_URI: process.env.REACT_APP_BASEURL + "/api/group",
  AUTOCREATE_POST_URI:
    process.env.REACT_APP_BASEURL + "/api/group/toggleautocreategroup",
  CAN_COLLECT_USER_INFO_POSTURI:
    process.env.REACT_APP_BASEURL + "/api/group/togglecollectuserinfo",
  GROUP_POST_URI: process.env.REACT_APP_BASEURL + "/api/group/create",
  GROUP_DELETE_URI: process.env.REACT_APP_BASEURL + "/api/group/delete",
  GROUP_GET_BY_ID_URI: process.env.REACT_APP_BASEURL + "/api/group/edit",
  GET_USERS_IN_GROUP_BY_ID_URI:
    process.env.REACT_APP_BASEURL + "/api/group/useringroup",
  UPDATE_GROUP_URI: process.env.REACT_APP_BASEURL + "/api/group/edit",
  GET_SHARED_COLLECTION_IN_GROUP_URI:
    process.env.REACT_APP_BASEURL + "/api/group/getsharedgroup",
  SEARCH_GROUP_BY_NAME: process.env.REACT_APP_BASEURL + "/api/group/search",
  POST_SHARED_CATEGORY_URI:
    process.env.REACT_APP_BASEURL + "/api/group/sharedcategorytogroup",

  //Category Collection
  GET_ALL_PUBLIC_COLLECTION: process.env.REACT_APP_BASEURL + "/api/category",
  GET_ALL_SUBCATEGORY_COLLECTION:
    process.env.REACT_APP_BASEURL + "/api/category/subcategory",
  POST_COLLECTION: process.env.REACT_APP_BASEURL + "/api/category/create",
  EDIT_COLLECTION_URI: process.env.REACT_APP_BASEURL + "/api/category/edit",
  DELETE_COLLECTION_URI: process.env.REACT_APP_BASEURL + "/api/category/delete",
  UPDATE_COLLECTION_ORDER_URI:
    process.env.REACT_APP_BASEURL + "/api/category/reordercollection",

  //Bookmark Collection
  GET_ALL_BOOKMARK_BY_ID: process.env.REACT_APP_BASEURL + "/api/bookmarks/getBookmarks",
  POST_BOOKMARK: process.env.REACT_APP_BASEURL + "/api/bookmarks/create",
  EDIT_BOOKMARK: process.env.REACT_APP_BASEURL + "/api/bookmarks/edit",
  DELETE_BOOKMARK: process.env.REACT_APP_BASEURL + "/api/bookmarks/delete",
  GET_RESOLVE_BOOKMARK:
    process.env.REACT_APP_BASEURL + "/api/bookmarks/resolve",
  GET_POST_RESOLVED_BOKMARK_DATA:
    process.env.REACT_APP_BASEURL + "/api/bookmarks/fetch",
  PASTE_BOOKMARK_URI: process.env.REACT_APP_BASEURL + "/api/bookmarks/paste",
  UPLOAD_BOOKMARK_URI: process.env.REACT_APP_BASEURL + "/api/bookmarks/uploadfile",
  BOOKMARK_CLICKED_COUNT:process.env.REACT_APP_BASEURL + "/api/bookmarks/clickedcount",
  BOOKMARK_CLICKED_USER_BY_ID:process.env.REACT_APP_BASEURL + "/api/bookmarks/bookmarkclickeduser",
  SEARCH_BOOKMARK_CLICKED_COUNT:process.env.REACT_APP_BASEURL + "/api/bookmarks/searchbookmarkcount",

  //Teams Collection
  GET_ALL_TEAMSCOLLECTION_URI:
    process.env.REACT_APP_BASEURL + "/api/teamscollection",
  DELETE_TEAMSCOLLECTION_BY_ID_URI:
    process.env.REACT_APP_BASEURL + "/api/teamscollection/delete",

  //Tags
  SEARCH_TAGS_URI: process.env.REACT_APP_BASEURL + "/api/tags/search",
  ADD_TAGS_TOBOOKMARK_URI: process.env.REACT_APP_BASEURL + "/api/tags/addtobookmark",
  GET_ALL_TAGS_URI: process.env.REACT_APP_BASEURL + "/api/tags",
  CHECK_TAG_VALID_URI: process.env.REACT_APP_BASEURL + "/api/tags/istagvalid",
  GET_TAG_BY_ID: process.env.REACT_APP_BASEURL + "/api/tags",
  POST_TAG_URI: process.env.REACT_APP_BASEURL + "/api/tags/create",
  DELETE_TAG_URI: process.env.REACT_APP_BASEURL + "/api/tags/delete",
  EDIT_TAG_URI: process.env.REACT_APP_BASEURL + "/api/tags/edit",

  //Subscription
  GET_SUBSCRIPTION_DETAILS_URI:
    process.env.REACT_APP_BASEURL + "/api/subscriptions/details",
  POST_SYNC_SUBSCRIPTION:
    process.env.REACT_APP_BASEURL + "/api/subscriptions/sync",
  POST_SUBSCRIPTION_PLAN_CHANGE:
    process.env.REACT_APP_BASEURL + "/api/subscriptions/changeplan",

  //Subscription Expired
  GET_SUBSCRIPTION_EXPIRED:
    process.env.REACT_APP_BASEURL + "/api/subscriptions/subscriptionexpired",

  //Settings
  USER_SETTING_GET_URI: process.env.REACT_APP_BASEURL + "/api/settings",
  UPDATE_RESOLVE_URL_TOOGLE_URI:
    process.env.REACT_APP_BASEURL + "/api/settings/edit",


    //Saas
    LOGIN_SAAS:process.env.REACT_APP_BASEURL + "/api/saas",
    GET_SAAS_DETAIL:process.env.REACT_APP_BASEURL + "/api/saas/sassdetail",
    POST_SAAS_DETAIL:process.env.REACT_APP_BASEURL + "/api/saas/post"
 
};
