import { Constants } from "../../constants/Constants";
import { axiosInstance } from "../apiHeader.service";

const subscriptionApi = {
    fetchSubscriptionPageDetails: async function () {
      const response = await axiosInstance.get<SubscriptionDetail>(
        `${Constants.GET_SUBSCRIPTION_DETAILS_URI}`
      );
      return response.data;
    },
    postSubscriptionSync:async function(subscriptionId:number){
      const response=await axiosInstance.post(`${Constants.POST_SYNC_SUBSCRIPTION}`,subscriptionId);
      return response;
    },
    postSubscriptionPlanChange:async function(data:ISubscriptionPlanUpdate){
      const response=await axiosInstance.post(`${Constants.POST_SUBSCRIPTION_PLAN_CHANGE}`,data);
      return response;
    }
}

export default subscriptionApi;