import axios from "axios";
import { cookies } from "./cookies.service";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_BASE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": window.location.origin,
    "Content-Type": "application/json",
  },
});



axiosInstance.interceptors.request.use(
  (config) => {
    if (cookies.get("token") !== null) {
      config.headers.authorization = `Bearer ${cookies.get("token")}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
    async (error) => {
      
      await errorHandler(error.request.status);
      if (error.response && error.response.status === 510) {
          const navigateTo = error.response.headers['x-navigate-to'];
          if (navigateTo) {
              window.location.href = navigateTo;
          }
      }
    return Promise.reject(error);
  }
);

export const axiosMultipartInstance = axios.create({
  baseURL: process.env.REACT_BASE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": window.location.origin,
    "Content-Type": "multipart/form-data",
  },
});

axiosMultipartInstance.interceptors.request.use(
  (config) => {
    if (cookies.get("token") !== null) {
      config.headers.authorization = `Bearer ${cookies.get("token")}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosMultipartInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
    async (error) => {

      await errorHandler(error.request.status);
      if (error.response && error.response.status === 510) {
          const navigateTo = error.response.headers['x-navigate-to'];
          if (navigateTo) {
              window.location.href = navigateTo;
          }
      }
    return Promise.reject(error);
  }
);

async function errorHandler(status: number) {
  switch (status) {
    case 500:
      break;
    case 401:
      cookies.delete("token");
          break;
    default:
      break;
  }
  return;
}
