

const Loader = () => {
  return (
    <div className="flex items-center  flex-col h-[80vh] w-full justify-center bg-gray-100 blur-400">
      <div className="flex flex-col items-center mb-8">
        <img src={`${process.env.REACT_APP_BASEURL}/images/brand/logo.png`} alt="" className="h-16 w-auto" />
        <strong className="text-gray-700 font-medium text-sm">TagMyFav</strong>
      </div>
      <div className="flex items-center justify-center">
        <span className="bg-blue-500 h-3 w-1 mx-1 animate-loader"></span>
        <span className="bg-blue-500 h-3 w-1 mx-1 animate-loader animation-delay-150"></span>
        <span className="bg-blue-500 h-3 w-1 mx-1 animate-loader animation-delay-300"></span>
        <span className="bg-blue-500 h-3 w-1 mx-1 animate-loader animation-delay-450"></span>
      </div>
    </div>
  );
};

export default Loader;
