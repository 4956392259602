import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./routes/routes";
import PrivateRoute from "./routes/routes.private";
import PublicRoute from "./routes/routes.public";

function App() {
  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        containerClassName="overflow-auto"
      />
      <Router>
        <Routes>
          {routes.map((routes, index) => {
            const { path, isProtected } = routes;
            return (
              <Route
                key={index}
                path={path}
                element={
                  isProtected ? (
                    <PrivateRoute {...routes} />
                  ) : (
                    <PublicRoute {...routes} />
                  )
                }
              />
            );
          })}
        </Routes>
      </Router>
    </>
  );
}

export default App;
