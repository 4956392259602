import { createContext, useEffect, useState } from "react";
// import Loader from "../common/loader/Loader";
import commonApi from "../services/apis/common.api";
import { Constants } from "../constants/Constants";
import subscriptionApi from "../services/apis/subscription.api";
import Loader from "../common/loader/Loader";

// import Loader from "../common/loader/Loader";
// import toast from "react-hot-toast";

interface AuthContextProps {
    user: IAuthUser;
    isAuthenticated: boolean;
    onLogin: () => void;
    onLogout: () => void;
    isLoading:boolean;
    hasExpired:boolean,
    setHasExpired:React.Dispatch<React.SetStateAction<boolean>>
}

const defaultValue: AuthContextProps = {
    user: {
        email: "",
        orgID: 0,
        orgName: "",
        subscriptionID: 0,
        userID: 0,
        userName: "",
        picture:"",
    },
    isAuthenticated: false,
    onLogin: () => { },
    onLogout: () => { },
    isLoading:false,
    hasExpired:false,
    setHasExpired: () => {}
}

export const AuthContext = createContext<AuthContextProps>(defaultValue);

export const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useState<IAuthUser>(defaultValue.user);
    const [isLoading, setLoader] = useState<boolean>(true);
    const [hasExpired,setHasExpired]=useState<boolean>(false);
    
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
        return localStorage.getItem('isAuthenticated') === 'false';
      });


    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname !== "/subscription-expired") {
          getUserData();         
        } else {
          setLoader(false);
        }
      }, []);

      useEffect(() => {
        if(isAuthenticated===true){
            const pathname = window.location.pathname;
            if (pathname !== "/subscription-expired") {
                checkSubscriptionExpired();
            }
        }
      }, [isAuthenticated]);

    const getUserData = () => {
        commonApi.fetchUser()
            .then((res) => {
                setIsAuthenticated(true);
                setUser(res);
                localStorage.setItem('isAuthenticated', 'true');
            })
            .catch((error: XMLHttpRequest) => {
                if (error.response && error.response.status === 401) {
                    setIsAuthenticated(false);
                    localStorage.removeItem('isAuthenticated');
                }
                // if(error.response && error.response.status === 500){
                //     setIsAuthenticated(true);
                //     toast.error("Something went wrong !!")
                // }
            })
            .finally(() => {
                setTimeout(() => {
                    setLoader(false);
                }, 0)
            }
            )
    };

    const checkSubscriptionExpired=()=>{
        subscriptionApi.fetchSubscriptionPageDetails().then((res)=>{
            setHasExpired(res.subs.hasExpired)
        })
    }

    const fetchInitialApis = () => {
        getUserData()
    }

    //login
    const loginHandler = () => {
        fetchInitialApis();
    };

    //logout
    const logoutHandler = () => {
        window.location.href = `${Constants.USER_SIGNOUT_URI}`
        localStorage.removeItem('isAuthenticated');
        /*  cookies.delete("token");
          setIsAuthenticated(false);
          setUser(defaultValue.user);*/
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthenticated,
                onLogin: loginHandler,
                onLogout: logoutHandler,
                isLoading,
                hasExpired,
                setHasExpired
            }}>
             {isLoading ? <Loader /> : <>{children}</>} 
            {/* <>{children}</> */}
        </AuthContext.Provider>
    )

}