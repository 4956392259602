import { Suspense, useContext } from 'react';
import Loader from '../common/loader/Loader';
import { IRoutes } from '../interface/routes';
import { Navigate } from "react-router-dom";
import { AuthContext } from '../context/auth.provider';

const PublicRoute = (
    {
        title,
        component: Component,
    }: IRoutes
) => {
    const { isAuthenticated, isLoading } = useContext(AuthContext);


    if (isLoading) {
        return <Loader />;
    }

    if (isAuthenticated && title === "Sign In") {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Suspense fallback={<Loader />}>
                <Component />
            </Suspense>
        </>
    )
}

export default PublicRoute;