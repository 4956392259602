import { Suspense, lazy, useContext } from 'react';
import Loader from '../common/loader/Loader';
import { IRoutes } from '../interface/routes';
import { Navigate } from "react-router-dom";
import { AuthContext } from '../context/auth.provider';
const DefaultLayout = lazy(() => import("../containers/DefaultLayout"));


const PrivateRoute = (
    {
        component: Component,
        withNavigation,
    }: IRoutes
) => {
    const { isAuthenticated, isLoading } = useContext(AuthContext)

    if (isLoading) {
        return <Loader />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    
    return (
        <>
          {withNavigation ? (
                <DefaultLayout>
                    <Suspense fallback={<Loader />}>
                        <Component />
                    </Suspense>
                </DefaultLayout>
            ) : (
                <Component />
            )}
        </>
    )
}

export default PrivateRoute;